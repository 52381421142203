<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="4">
        <Select
          v-model="query.customerId"
          size="small"
          placeholder="选择客户"
        >
          <option
            v-for="item in customerList"
            :key="'customerId_'+item.id"
            :value="item.id"
          >{{item.name}}</option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.assetId"
          size="small"
          placeholder="选择资产"
        >
          <option
            v-for="item in assetArray"
            :key="'assetId_'+item.assetId"
            :value="item.assetId"
          >{{item.assetName}}</option>
        </Select>
      </i-col>
      <i-col span="4">
        <DatePicker
          v-model="query.actionDate"
          type="date"
          size="small"
          placeholder="选择刊播日期"
          style="width:100%"
        ></DatePicker>
      </i-col>
      <i-col span="4">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="handleSearch"
        >搜索</Button>
        <Button
          size="small"
          type="success"
          class="m-l-5"
          @click="handleDownload"
        >导出EXCEL</Button>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        size="small"
        :data="list"
        :columns="tableColumns"
      ></Table>
      <div class="paging_style">
        <Page
          size="small"
          :total="total"
          :page-size="query.pageSize"
          show-total
          show-elevator
          :current="query.pageNumber"
          @on-change="handlePageChange"
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 15,
        customerId: null,
        actionDate: '',
        assetId: null
      },
      customerList: [],
      assetArray: [],
      list: [
        { customerId: 1, customerName: 'xxx客户', brandId: 1, brandName: 'xxx品牌', actionDate: '2021-09-30', assetList: [{ assetId: 1, assetName: '某地铁1号线' }, { assetId: 2, assetName: '某地铁2号线' }, { assetId: 3, assetName: '某地铁3号线' }], productList: [{ productId: 1, productName: 'PIS' }, { productId: 2, productName: 'LED屏' }], standardList: [{ unitDuration: 5, playTimes: 20 }, { unitDuration: 15, playTimes: 100 }, { unitDuration: 30, playTimes: 200 }] },
        { customerId: 1, customerName: 'xxx客户', brandId: 1, brandName: 'xxx品牌', actionDate: '2021-09-30', assetList: [{ assetId: 1, assetName: '某地铁1号线' }, { assetId: 2, assetName: '某地铁2号线' }, { assetId: 3, assetName: '某地铁3号线' }], productList: [{ productId: 1, productName: 'PIS' }, { productId: 2, productName: 'LED屏' }], standardList: [{ unitDuration: 5, playTimes: 20 }, { unitDuration: 15, playTimes: 100 }, { unitDuration: 30, playTimes: 200 }] },
        { customerId: 1, customerName: 'xxx客户', brandId: 1, brandName: 'xxx品牌', actionDate: '2021-09-30', assetList: [{ assetId: 1, assetName: '某地铁1号线' }, { assetId: 2, assetName: '某地铁2号线' }, { assetId: 3, assetName: '某地铁3号线' }], productList: [{ productId: 1, productName: 'PIS' }, { productId: 2, productName: 'LED屏' }], standardList: [{ unitDuration: 5, playTimes: 20 }, { unitDuration: 15, playTimes: 100 }, { unitDuration: 30, playTimes: 200 }] },
        { customerId: 1, customerName: 'xxx客户', brandId: 1, brandName: 'xxx品牌', actionDate: '2021-09-30', assetList: [{ assetId: 1, assetName: '某地铁1号线' }, { assetId: 2, assetName: '某地铁2号线' }, { assetId: 3, assetName: '某地铁3号线' }], productList: [{ productId: 1, productName: 'PIS' }, { productId: 2, productName: 'LED屏' }], standardList: [{ unitDuration: 5, playTimes: 20 }, { unitDuration: 15, playTimes: 100 }, { unitDuration: 30, playTimes: 200 }] },
        { customerId: 1, customerName: 'xxx客户', brandId: 1, brandName: 'xxx品牌', actionDate: '2021-09-30', assetList: [{ assetId: 1, assetName: '某地铁1号线' }, { assetId: 2, assetName: '某地铁2号线' }, { assetId: 3, assetName: '某地铁3号线' }], productList: [{ productId: 1, productName: 'PIS' }, { productId: 2, productName: 'LED屏' }], standardList: [{ unitDuration: 5, playTimes: 20 }, { unitDuration: 15, playTimes: 100 }, { unitDuration: 30, playTimes: 200 }] },
        { customerId: 1, customerName: 'xxx客户', brandId: 1, brandName: 'xxx品牌', actionDate: '2021-09-30', assetList: [{ assetId: 1, assetName: '某地铁1号线' }, { assetId: 2, assetName: '某地铁2号线' }, { assetId: 3, assetName: '某地铁3号线' }], productList: [{ productId: 1, productName: 'PIS' }, { productId: 2, productName: 'LED屏' }], standardList: [{ unitDuration: 5, playTimes: 20 }, { unitDuration: 15, playTimes: 100 }, { unitDuration: 30, playTimes: 200 }] },
        { customerId: 1, customerName: 'xxx客户', brandId: 1, brandName: 'xxx品牌', actionDate: '2021-09-30', assetList: [{ assetId: 1, assetName: '某地铁1号线' }, { assetId: 2, assetName: '某地铁2号线' }, { assetId: 3, assetName: '某地铁3号线' }], productList: [{ productId: 1, productName: 'PIS' }, { productId: 2, productName: 'LED屏' }], standardList: [{ unitDuration: 5, playTimes: 20 }, { unitDuration: 15, playTimes: 100 }, { unitDuration: 30, playTimes: 200 }] }
      ],
      total: 0,
      tableColumns: [
        { title: '刊播日期', align: 'center', key: 'actionDate' },
        {
          title: '客户（品牌）',
          align: 'center',
          key: 'customerName',
          render: (h, params) => {
            return h('span', `${params.row.customerName}（${params.row.brandName}）`)
          }
        },
        {
          title: '资产',
          align: 'center',
          render: (h, params) => {
            const html = []
            params.row.assetList.forEach(item => {
              html.push(h('p', item.assetName))
            })
            return h('div', html)
          }
        },
        {
          title: '投放产品',
          align: 'center',
          render: (h, params) => {
            const html = []
            params.row.productList.forEach(item => {
              html.push(h('p', item.productName))
            })
            return h('div', html)
          }
        },
        {
          title: '投放规格',
          align: 'center',
          render: (h, params) => {
            const html = []
            params.row.standardList.forEach(item => {
              html.push(h('p', `${item.unitDuration} 秒（${item.playTimes} 次）`))
            })
            return h('div', html)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleViewDetail(params.row)
                }
              }
            }, '查看')
          }
        }
      ]
    }
  },
  created () {
    // 设置头部路由标签
    this.setLinkTagArray()
    this.setLinkTagArray({ key: 'eleMediaPublishIndex', value: '刊播列表' })
    this.setLinkRouterMaps(['eleMediaPublishIndex', () => { this.handleIndexPage() }])
    this.setActivedTagName('eleMediaPublishIndex')
  },
  methods: {
    initData () {

    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleViewDetail (params) {
      this.handelDetailPage()
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'eleMediaPublishDetail', value: '刊播详情' })
      this.setLinkRouterMaps(['eleMediaPublishDetail', () => { this.handelDetailPage() }])
      this.setActivedTagName('eleMediaPublishDetail')
    },
    handleDownload () {

    },
    handleIndexPage () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      // this.setLeftComponent('CustomerList')
      this.setBottomComponent('IndexTable')
    },
    handelDetailPage () {
      // this.setLeftComponent('ActionDateView')
      this.setShowLeftBlock(false)
      // this.setRightComponent('BasicInfo')
      this.setBottomComponent('Detail')
    }
  }
}
</script>
